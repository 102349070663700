import { FC } from "react";

import Tabs from "components/tabs";
import List from "components/list";

import { useCategories } from "./duck/hooks";
import { useCoinsURLParams } from "../../duck/hooks";

import Search from "./components/search";

import classes from "./styles/classes.module.scss";

const Header: FC = () => {
  const categories = useCategories();
  const { params, setAllParams } = useCoinsURLParams();

  return (
    <div className={classes.wrapper}>
      <Search />
      <Tabs
        items={categories}
        value={params.category}
        className={classes.tabsWrapper}
        onChange={({ value }) => setAllParams({ params: { category: value } })}
      />
      <List.Header>
        <List.HeaderCell sortKey="rank" reverseDefaultSort>
          #
        </List.HeaderCell>
        <List.HeaderCell sortKey="name">NAME</List.HeaderCell>
        <List.HeaderCell sortKey="price">PRICE</List.HeaderCell>
        <List.HeaderCell>MARKET CAP</List.HeaderCell>
        <List.HeaderCell sortKey="percent_24">24H %</List.HeaderCell>
        <List.HeaderCell>7D %</List.HeaderCell>
        <List.HeaderCell align="right" />
        <List.HeaderCell align="right" />
      </List.Header>
    </div>
  );
};

export default Header;
