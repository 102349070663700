import { useEffect, useState } from "react";
import joinClassNames from "classnames";

import SearchIcon from "icons/search.svg?react";

import useWindowSizes from "hooks/use-window-sizes";

import NativeSearch from "components/search";

import Button from "basics/button";

import { useCoinsURLParams } from "../../../../duck/hooks";

import classes from "./styles/classes.module.scss";

const Search = () => {
  const { search, setSearchParams } = useCoinsURLParams();
  const { isMobileWidth } = useWindowSizes();
  const isSearch = Boolean(search.search);
  const [isSearchOpened, setIsSearchOpened] = useState(isSearch);

  useEffect(() => {
    if (isMobileWidth) {
      setIsSearchOpened(isSearch);

      return;
    }

    setIsSearchOpened(false);
  }, [isMobileWidth]);

  let content;

  if (isSearchOpened) {
    content = (
      <NativeSearch
        autoFocus
        onClose={() => setIsSearchOpened(false)}
        classNames={{ wrapper: classes.searchWrapper }}
        className={classes.search}
        name="cryptocurrency"
        placeholder="Search for coins"
        initialValue={search.search}
        onSearchChange={search => setSearchParams({ search })}
      />
    );
  } else {
    content = (
      <>
        <h1 className={classes.title}>Coins</h1>
        {isMobileWidth ? (
          <Button
            isIconOnly
            themeName="default"
            className={classes.openSearchButton}
            onClick={() => {
              setIsSearchOpened(true);
            }}
          >
            <SearchIcon className={classes.searchIcon} />
          </Button>
        ) : (
          <NativeSearch
            classNames={{ wrapper: classes.searchWrapper }}
            className={classes.search}
            name="cryptocurrency"
            placeholder="Search for coins"
            initialValue={search.search}
            onSearchChange={search => setSearchParams({ search })}
          />
        )}
      </>
    );
  }

  return (
    <div className={joinClassNames(classes.headerPaddings, classes.header)}>
      {content}
    </div>
  );
};

export default Search;
