import { CSSProperties, FC } from "react";
import { Link } from "@remix-run/react";

import Button, { Sizes } from "basics/button";

import { Percent, Price, Amount } from "components/numbers";
import PinButton from "components/pin-button";
import { CoinIcon } from "components/icon";
import List from "components/list";

import { urlSelector } from "selectors/url";

import classes from "./styles/classes.module.scss";

interface Props {
  style: CSSProperties;
  item: Coin;
  isCurrenciesLoaded: boolean;
  coinIconSize: "xs" | "sm";
  buttonSize: Sizes;
}

const CoinRow: FC<Props> = ({
  item: {
    letterId,
    rank,
    name,
    symbol,
    logo,
    buyAvailable,
    lastMarketQuote: { percentChange24H, percentChange7D, marketCap, price },
  },
  style,
  isCurrenciesLoaded,
  buttonSize,
  coinIconSize,
}) => (
  <List.Row as={Link} to={urlSelector.coin({ id: letterId })} style={style}>
    <List.Cell className={classes.rank}>{rank}</List.Cell>
    <List.Cell>
      <CoinIcon src={logo} size={coinIconSize} />
      <div className={classes.coinInfo}>
        <p className={classes.coinName}>{name}</p>
        <p className={classes.coinCode}>{symbol}</p>
      </div>
    </List.Cell>
    <List.Cell>
      {isCurrenciesLoaded ? (
        <Price className={classes.price} value={price} isEquivalent />
      ) : (
        <div className={classes.skeleton} />
      )}
    </List.Cell>
    <List.Cell>
      {isCurrenciesLoaded ? (
        <Amount type="summary" value={marketCap} />
      ) : (
        <div className={classes.skeleton} />
      )}
    </List.Cell>
    <List.Cell className={classes.percentChange}>
      <Percent
        className={classes.percent}
        type="markets"
        value={percentChange24H}
      />
    </List.Cell>
    <List.Cell className={classes.percentChange}>
      <Percent
        className={classes.percent}
        type="markets"
        value={percentChange7D}
      />
    </List.Cell>
    <List.Cell align="right">
      {buyAvailable && (
        <Button
          size={buttonSize}
          as={Link}
          to={urlSelector.buy({ coinLetterId: letterId })}
        >
          Buy
        </Button>
      )}
    </List.Cell>
    <List.Cell align="right">
      <PinButton themeName="ghost" id={letterId} size={buttonSize} />
    </List.Cell>
  </List.Row>
);

export default CoinRow;
