export const getListCellSchema = windowSizes => {
  const {
    isDesktopWidth,
    isTabletSmallWidth,
    isMobileMediumWidth,
    isMobileSmallWidth,
    isMobileWidth,
  } = windowSizes;
  let actionWidth = 110;
  if (isTabletSmallWidth) {
    actionWidth = 80;
  } else if (isMobileSmallWidth) {
    actionWidth = 58;
  }

  const schema = [
    { width: 32 },
    { flex: 1.5 },
    { flex: 1 },
    { flex: 1 },
    { flex: 1 },
    { flex: 1 },
    { width: actionWidth },
    { width: 56 },
  ];

  if (!isDesktopWidth) {
    // hide 7d column
    schema[5] = null;
  }

  if (isMobileWidth || isTabletSmallWidth) {
    // hide market cap column
    schema[3] = null;
  }

  if (isMobileWidth) {
    // hide 24h column
    schema[4] = null;
  }

  if (isMobileSmallWidth || isMobileMediumWidth) {
    schema[7] = null;
  }

  return schema;
};
