import { useMemo } from "react";

import useSelector from "hooks/redux/use-selector";

export const useCategories = () => {
  const categories = useSelector(state => state.coins.categories);

  return useMemo(
    () =>
      categories.map(category => ({
        label: category.displayName,
        value: category.id,
      })),
    [categories],
  );
};
