import { mixed, string } from "yup";

import { combineValidator } from "operations/validate";

import { CoinsURL } from "./types";

export const validator = combineValidator<Partial<CoinsURL>>({
  search: string().optional(),
  sort: mixed<CoinsSort>()
    .oneOf([
      "-rank",
      "rank",
      "-name",
      "name",
      "-percent_24",
      "percent_24",
      "-price",
      "price",
    ])
    .optional(),
});
